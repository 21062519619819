var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"color":"#F06835","outlined":"","dense":"","label":"Search Team Member"},model:{value:(_vm.teamMemberQuery),callback:function ($$v) {_vm.teamMemberQuery=$$v},expression:"teamMemberQuery"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.viewByRoleItems,"item-text":"name","item-value":"value","label":"Choose Role..."},model:{value:(_vm.viewByRole),callback:function ($$v) {_vm.viewByRole=$$v},expression:"viewByRole"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"12","md":"6"}},[_c('router-link',{staticClass:"blue--text",attrs:{"to":"/message-center/messages/new?recipient=MyYNTeam"}},[_c('v-btn',{staticClass:"orange-bg white--text"},[_vm._v("Message My Team")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.members,"item-key":"id","loading":_vm.isLoading,"server-items-length":_vm.totalRecords,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRolesString(item.role.$values))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.statusDialog),callback:function ($$v) {_vm.$set(item, "statusDialog", $$v)},expression:"item.statusDialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_vm._v("Are you sure you want to "+_vm._s(item.isActive ? "suspend" : "resume")+" access for "+_vm._s(item.fullName)+" ? ")]),_c('v-card-actions',[_c('v-spacer'),(!_vm.statusProcessing)?_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.statusDialog = false}}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.statusProcessing},on:{"click":function($event){return _vm.toggleStatus(item)}}},[_vm._v(" Confirm ")])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":("/message-center/messages/new?recipient=" + (item.id))}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#31C0E3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-email ")])],1)]}}],null,true)},[_c('span',[_vm._v("Send Message")])]),(
          _vm.permissions &&
            _vm.permissions.ynDirectory &&
            _vm.permissions.ynDirectory.canEdit
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-account-details ")])]}}],null,true)},[_c('span',[_vm._v("Edit User Permissions")])]):_vm._e(),(
          _vm.permissions &&
            _vm.permissions.ynDirectory &&
            _vm.permissions.ynDirectory.canEdit
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ",class:item.isActive ? 'orange' : 'blue',attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();item.statusDialog = true}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.isActive ? "Suspend" : "Resume")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isActive ? "Suspend" : "Resume")+" Access")])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }