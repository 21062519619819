<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          class="mt-4"
          v-model="teamMemberQuery"
          color="#F06835"
          outlined
          dense
          label="Search Team Member"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="viewByRole"
          :items="viewByRoleItems"
          item-text="name"
          item-value="value"
          label="Choose Role..."
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end align-center">
        <router-link
          class="blue--text"
          :to="`/message-center/messages/new?recipient=MyYNTeam`"
          ><v-btn class="orange-bg white--text"
            >Message My Team</v-btn
          ></router-link
        >
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="members"
      item-key="id"
      :loading="isLoading"
      :server-items-length="totalRecords"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template #[`item.role`]="{ item }">
        {{ getRolesString(item.role.$values) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-dialog v-model="item.statusDialog" persistent max-width="290">
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text
              >Are you sure you want to
              {{ item.isActive ? "suspend" : "resume" }} access for
              {{ item.fullName }}
              ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!statusProcessing"
                color="green darken-1"
                text
                @click="item.statusDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="toggleStatus(item)"
                :loading="statusProcessing"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <router-link
              :to="`/message-center/messages/new?recipient=${item.id}`"
            >
              <v-icon class="mr-2" color="#31C0E3" v-bind="attrs" v-on="on">
                mdi-email
              </v-icon>
            </router-link>
          </template>
          <span>Send Message</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            permissions &&
              permissions.ynDirectory &&
              permissions.ynDirectory.canEdit
          "
        >
          <template #activator="{on, attrs}">
            <v-icon class="mr-2" v-bind="attrs" v-on="on">
              mdi-account-details
            </v-icon>
          </template>
          <span>Edit User Permissions</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            permissions &&
              permissions.ynDirectory &&
              permissions.ynDirectory.canEdit
          "
        >
          <template #activator="{on, attrs}">
            <v-btn
              small
              class="white--text "
              v-bind="attrs"
              v-on="on"
              @click.stop="item.statusDialog = true"
              :class="item.isActive ? 'orange' : 'blue'"
            >
              {{ item.isActive ? "Suspend" : "Resume" }}
            </v-btn>
          </template>
          <span>{{ item.isActive ? "Suspend" : "Resume" }} Access</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { GET_QUERY, POST_DATA, API_EMPLOYEES } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { mdiAccountEditOutline } from "@mdi/js";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  data: () => ({
    isLoading: false,
    statusProcessing: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "fullName",
        class: "orange-bg"
      },
      { text: "Role", value: "role", class: "orange-bg" },
      { text: "Email", value: "email", class: "orange-bg" },
      { text: "Phone", value: "phone", class: "orange-bg", sortable: false },
      {
        text: "Permissions",
        value: "permissions",
        class: "orange-bg",
        sortable: false
      },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    members: [],
    totalRecords: null,
    teamMemberQuery: "",
    viewByRole: "all", //{ name: "All team members", value: "all" },
    viewByRoleItems: [
      { name: "All team members", value: "all" },
      { name: "Executive", value: "Executive" },
      { name: "Regional Manager", value: "Regional Manager" },
      { name: "Branch Manager", value: "Branch Manager" },
      { name: "Account Manager", value: "Account Manager" },
      { name: "Crew Leader", value: "Crew Leader" }
    ],
    accountEditIcon: mdiAccountEditOutline,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["fullName"],
      sortDesc: [false]
    },
    permissions: []
  }),
  watch: {
    teamMemberQuery: async function() {
      await this.getEmployees();
      // if (this.teamMemberQuery && this.teamMemberQuery.length > 0) {
      //   await this.getUsers();
      // } else {
      //   this.users = [];
      // }
    },
    viewByRole: async function() {
      await this.getEmployees();
    },
    "options.sortBy": async function() {
      await this.getEmployees();
      // if (this.userQuery && this.userQuery.length > 0) {
      //   await this.getUsers();
      // } else {
      //   this.users = [];
      // }
    }
  },
  async mounted() {
    this.isLoading = true;
    permissionsHelper.getPermissions().then(this.getPermissions);
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "YN Team Members" }]);
    await this.getEmployees();
    this.isLoading = false;
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    toggleStatus(item) {
      if (this.statusProcessing) return;

      this.statusProcessing = true;

      // item.isActive = !item.isActive;

      let url = `${API_EMPLOYEES}/${item.aspireId}/${
        item.isActive ? "suspend" : "activate"
      }`;
      this.$store
        .dispatch(POST_DATA, {
          listName: url
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            item.isActive = response.data.isActive;
            this.$snackbar.showMessage({
              content: "Member has been updated"
            });
          }
          this.statusProcessing = false;
          item.statusDialog = false;
        });
    },
    async getEmployees() {
      this.loading = true;

      this.isLoading = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_EMPLOYEES
          // ,
          // params: {
          //   // query: this.userQuery,
          //   // skip: this.options.itemsPerPage * (this.options.page - 1),
          //   // take:
          //   //   this.options.itemsPerPage === -1
          //   //     ? this.totalUsers
          //   //     : this.options.itemsPerPage,
          //   // sort: this.sort
          // }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            let items = response.data; //.filter(el => el.role.$values.length > 0);
            items.forEach(element => {
              element.statusDialog = false;
              element.permissionsDialog = false;
            });
            let filteredItems = [];
            if (this.viewByRole && this.viewByRole !== "all") {
              for (let i = 0; i < items.length; i++) {
                if (items[i].role.$values) {
                  for (let j = 0; j < items[i].role?.$values.length; j++)
                    if (items[i].role.$values[j].name === this.viewByRole)
                      filteredItems.push(items[i]);
                }
              }

              items = filteredItems;
            }

            if (this.teamMemberQuery && this.teamMemberQuery.length > 0) {
              items = items.filter(
                el =>
                  el.fullName
                    .toLowerCase()
                    .indexOf(this.teamMemberQuery.toLowerCase()) > -1 ||
                  el.email
                    .toLowerCase()
                    .indexOf(this.teamMemberQuery.toLowerCase()) > -1 ||
                  el.phone
                    .toLowerCase()
                    .indexOf(this.teamMemberQuery.toLowerCase()) > -1
              );
              // let queryFilteredItmes = [];
              // for (let i = 0; i < items.length; i++) {
              //   if (
              //     items[i].fullName
              //       .toLowerCase()
              //       .indexOf(this.teamMemberQuery.toLowerCase()) > -1 ||
              //     items[i].email
              //       .toLowerCase()
              //       .indexOf(this.teamMemberQuery.toLowerCase()) > -1 ||
              //     items[i].phone
              //       .toLowerCase()
              //       .indexOf(this.teamMemberQuery.toLowerCase()) > -1
              //   )
              //     queryFilteredItmes.push(items[i]);
              // }

              // items = queryFilteredItmes;
            }

            const total = items.length;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            if (itemsPerPage > 0) {
              items = items.slice(
                (page - 1) * itemsPerPage,
                page * itemsPerPage
              );
            }

            this.members = items;
            this.totalRecords = total;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loading = false;
          this.isLoading = false;
        });
    },
    getRolesString(roles) {
      if (!roles) return "";
      let rolesStr = "";
      for (let i = 0; i < roles.length; i++) {
        rolesStr += roles[i].name + "; ";
      }
      if (rolesStr === "") return "---";
      // remove last "; "
      rolesStr = rolesStr.substring(0, rolesStr.length - 2);
      return rolesStr;
    }
  },
  computed: {
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
